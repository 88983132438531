import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from "axios";

import { authService } from "../util/authService";
import {
  UnauthenticatedError,
} from "../util/errors";
import { makeErrorResponse } from "./errors";
//! Please change this back before deploying to the server!!!
const baseURL = "https://testapp.flyzephur.com:3001/api"; // http://localhost:3000/api

const _fetcher = axios.create({
  baseURL,
});

_fetcher.interceptors.response.use(
  (res) => {
    // Return just the backend JSON response rather than an axios response config.
    return res.data;
  },
  (err: AxiosError) => {
    // console.log("Got err?", err);
    if (err.response) {
      const e = makeErrorResponse(err.response.data);
      if (e) throw e;
    }
    return Promise.reject(err);
  }
);

export class Fetcher {
  /**
   * Helper function that makes a request equivalent to axios.request, but doesn't throw on a response
   * code that isn't 2xx
   */
  async _req<R>(config: AxiosRequestConfig) {
    try {
      const res = await _fetcher.request<R>(config);
      return res;
    } catch (_err) {
      // console.log("_req: caught error", _err);
      const err = _err as AxiosError;
      if (err.response && err.response.status === 401) {
        // Request successful, but the server responded with a status code
        // outside 2xx
        return err.response;
      }
      throw err;
    }
  }

  /**
   * Gets a new access token and saves to token service, redirecting to login if no refresh
   * token is available.
   */
  async getNewAccessToken() {
    const refreshToken = await authService.getRefreshToken();
    if (!refreshToken) {
      throw new UnauthenticatedError();
    }

    const res = await _fetcher.request<{ accessToken: string }>({
      method: "post",
      url: "/token",
      data: {
        refreshToken,
      },
    });
    await authService.setAccessToken(res.data.accessToken);
    return res.data.accessToken;
  }

  _setHeader(config: AxiosRequestConfig, token: string) {
    if (!config.headers) {
      config.headers = {};
    }
    config.headers.Authorization = `Bearer ${token}`;
  }

  /**
   * @param {AxiosRequestConfig} config
   * @param {{ useAccessToken?: boolean } | undefined} options
   * @returns
   */
  async request<R>(
    config: AxiosRequestConfig,
    options?: { useAccessToken?: boolean }
  ) {
    try {
      if (options && options.useAccessToken) {
        const token = await authService.getAccessToken();
        if (token) {
          this._setHeader(config, token);
        }
      }

      let res = await this._req<R>(config);
      if (res.status === 401) {
        // Retry the same request by trying a new access token.
        const refreshToken = await authService.getRefreshToken();
        if (!refreshToken) {
          throw new UnauthenticatedError();
        }
        const accessToken = await this.getNewAccessToken();
        this._setHeader(config, accessToken);
        res = await _fetcher.request<R>(config);
      }
      return res as AxiosResponse<R>;
    } catch (_err) {
      const err = _err as AxiosError;
      if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log("Here");
        // console.log(err.response.data);
        // console.log(err.response.status);
        // console.log(err.response.headers);
      } else if (err.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        // console.log("Fetcher: No response", err.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        // console.log(
        //   "Fetcher: Something weird happened",
        //   err,
        //   err.message,
        //   JSON.stringify(err)
        // );
      }
      throw err;
    }
  }
}

export const fetcher = new Fetcher();
