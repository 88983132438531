import * as React from "react";
import { FieldError } from "react-hook-form";
import TextInput from "../TextInput";
import { en, registerTranslation, DatePickerModal } from 'react-native-paper-dates';
import { TouchableOpacity } from "react-native";
import { setStatusBarNetworkActivityIndicatorVisible } from "expo-status-bar";
registerTranslation('en', en)


export interface DatePickerInputProps {
  value?: string;
  label?: string;
  onChange?: (...vals: any[]) => void;
  error?: FieldError | undefined;
  startDate?: Date | undefined;
  endDate?: Date | undefined;
}

export default React.forwardRef<any, DatePickerInputProps>((props, ref) => {
  const [date, setDate] = React.useState<Date | undefined>(undefined);
  const [open, setOpen] = React.useState(false);
  const { value, label, onChange, error } = props;
  const onDismissSingle = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onConfirmSingle = React.useCallback(
    (params) => {
      setOpen(false);
      setDate(params.date);
    },
    [setOpen, setDate]
    
  );
  return (
    <>
    <TouchableOpacity  onPress={() => setOpen(true)}>
      <DatePickerModal
        locale="en"
        mode="single"
        visible={open}
        onDismiss={onDismissSingle}
        date={date}
        onConfirm={onConfirmSingle}
        validRange={{
          startDate: props.startDate || new Date(),
          endDate: props.endDate || undefined
        }}
        onChange={(d) => onChange(d.date?.toLocaleDateString("en-US"))} // same props as onConfirm but triggered without confirmed by user
        // saveLabel="Save" // optional
        // uppercase={false} // optional, default is true
        // label="Select date" // optional
        // animationType="slide" // optional, default is 'slide' on ios/android and 'none' on web
      />
      <TextInput
      
        placeholder="mm/dd/yyyy"
        ref = {ref} 
        editable={false}  
        value={value == "" ? date?.toLocaleDateString("en-US") : value}
        // onChangeText ={(val) => setText(val)}
        onSelectionChange = {onChange}
        label={label}
        error={error}
        //mask = "MMDDYYYY"
      />
    </TouchableOpacity> 
    </>
  );
  
});

