import * as React from "react";
import { ScrollView, StyleSheet } from "react-native";
import { NativeStackHeaderProps } from "@react-navigation/native-stack";
import { useMutation, useQueryClient } from "react-query";

import Theme from "../constants/Theme";
import { useUser, useTrip, useTripPilots } from "../hooks";
import SharedStyles from "../constants/SharedStyles";
import { View, Text } from "../components/Themed";
import { Button } from "../components/Button";
import { getDeparture, getReturn } from "../util/trips";
import { BookTripScreenProps, HomeStackNavigationProp } from "../navigation/types";
import { api } from "../api";
import { BaseHeader } from "../components/BaseHeader";
import { StripeCheckoutDto } from "../types/Stripe"
import SubtleButton from "../components/SubtleButton";
import { TripStatus } from "../types/Trip";
import { AuthProvider } from "../providers";

export default function BookTripScreen( { route, navigation }: BookTripScreenProps ) {
	const { tripId } = route.params;
	const { trip } = useTrip( tripId );
	const { tripPilots } = useTripPilots( tripId );
	const departure = trip ? getDeparture( trip ) : null;
	const tReturn = trip ? getReturn( trip ) : null;
	const [errMsg, setErrMsg] = React.useState( "" );
	const [loading, setLoading] = React.useState( false );

	// @ts-ignore
	const subbedTrip = !!tripPilots?.find( ( tp ) => ![null, '', undefined].includes( tp.subcontractedFrom?.id ) && tp.pilotStatus === TripStatus.ACCEPTED );

	const days = (( new Date( tReturn?.date as string ).getTime() - new Date( departure?.date as string ).getTime() ) / ( 1000 * 3600 * 24 )) + 1;

	const tripPrice = tReturn ? days * ( trip?.aircraft.dayRate as number ) as number : trip?.aircraft.dayRate as number;

	const price = tripPrice + 35 + ( subbedTrip ? ( tripPrice * 0.015 ) : 0 );
  
  const zephurPercentage = ( ( 1.0237 / price ) + 0.029 ) / 0.971;

	const totalPrice = Math.round( 100 * ( price + .30 + price * zephurPercentage ) );

	const dto = { tripId, totalPrice }

	const openPaymentSheet = async () => {
		try {
			setLoading( true );
			await api.stripeCheckout( dto as StripeCheckoutDto );
		} catch ( err ) {
			setErrMsg( "There was an Error Booking this Trip. Please try again later." )
		}
	};

	const cancel = () => {
		if ( navigation.canGoBack() ) navigation.goBack();
		else navigation.navigate( "Home" );
	}

	return (
		<View style={Theme.page}>
			<Text style={styles.header}>Confirm Trip Booking</Text>
			<Text style={styles.subheader}>Please click the button below to book your trip.</Text>
			<Text style={Theme.errorMessage}>{ errMsg }</Text>
      <View style={styles.receiptContainer}>
        <Text style={styles.receiptHeader}>Payment Breakdown</Text>
        <View style={styles.detailsContainer}>
          <Text style={styles.label}>Trip Price:</Text>
          <Text style={styles.value}>${tripPrice}</Text>
        </View>
        <View style={styles.detailsContainer}>
          <Text style={styles.label}>Zephur Fee:</Text>
          <Text style={styles.value}>$35</Text>
        </View>
        {subbedTrip && (
          <View style={styles.detailsContainer}>
            <Text style={styles.label}>Subcontractor Fee:</Text>
            <Text style={styles.value}>${( tripPrice * 0.015 ).toFixed( 2 )}</Text>
          </View>
        )}
        <View style={styles.detailsContainer}>
          <Text style={styles.label}>Stripe Fee:</Text>
          <Text style={styles.value}>${( ( totalPrice / 100 ) - tripPrice - 35 - ( subbedTrip ? tripPrice * 0.015 : 0 ) ).toFixed( 2 )}</Text>
        </View>
        <View style={styles.detailsContainer}>
          <Text style={styles.label}>Total:</Text>
          <Text style={styles.value}>${totalPrice / 100}</Text>
        </View>
      </View>
      <View style={Theme.spacer} />
      <Button
				variant="large"
				title="Book Trip"
				color="lightBlue"
				disabled={loading}
				onPress={openPaymentSheet}
			/>
			<View style={Theme.spacer} />
			<SubtleButton
			  title="Oops! I don't want to book this trip."
				onPress={cancel}
			/>
		</View>
	)
}

export function BookTripScreenHeader( { route, navigation }: NativeStackHeaderProps ) {
	const { tripId } = route.params as any;

	const onBack = () => {
		if ( navigation.canGoBack() ) navigation.goBack();
		else navigation.navigate( 'Trip', { tripId } );
	};

	return <BaseHeader leftIcon="back" color="black" onBack={onBack} />
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  sectionContainer: {
    ...SharedStyles.sectionContainer,
  },
  receiptContainer: {
    padding: 20,
    marginHorizontal: "auto",
    width: "75%",
  },
  sectionHeader: {
    ...SharedStyles.sectionHeader,
  },
  detailsContainer: {
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    merginVertical: 10,
  },
  label: {
    width: 120,
    fontWeight: "bold",
  },
  value: {
    marginLeft: 20,
  },
  clientDetailsSpacer: {
    height: 20,
  },
  aditDetailsSpacer: {
    height: 20,
  },
  header: {
    ...Theme.h1,
    textAlign: "center",
  },
  receiptHeader: {
    fontSize: 24,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 20,
  },
  subheader: {
    textAlign: "center",
  },
  errMsg: {
    ...Theme.errorMessage,
  },
  confirmBtn: {
    alignSelf: "center",
    marginBottom: 20,
  },
});

// const obj = {
//   id: "ch_3LcZSNBrL0aPwZOe3UYcGqfD",
//   object: "charge",
//   amount: 55853,
//   amount_captured: 55853,
//   amount_refunded: 0,
//   application: null,
//   application_fee: null,
//   application_fee_amount: null,
//   balance_transaction: "txn_3LcZSNBrL0aPwZOe3VADtLgo",
//   billing_details: {
//     address: {
//       city: null,
//       country: "US",
//       line1: null,
//       line2: null,
//       postal_code: "12548",
//       state: null,
//     },
//     email: "Aidan55@gmail.com",
//     name: "name",
//     phone: null,
//   },
//   calculated_statement_descriptor: "Stripe",
//   captured: true,
//   created: 1661885232,
//   currency: "usd",
//   customer: "cus_MJlmZarEiH9slf",
//   description: null,
//   destination: null,
//   dispute: null,
//   disputed: false,
//   failure_balance_transaction: null,
//   failure_code: null,
//   failure_message: null,
//   fraud_details: {},
//   invoice: null,
//   livemode: false,
//   metadata: {},
//   on_behalf_of: null,
//   order: null,
//   outcome: {
//     network_status: "approved_by_network",
//     reason: null,
//     risk_level: "normal",
//     risk_score: 15,
//     seller_message: "Payment complete.",
//     type: "authorized",
//   },
//   paid: true,
//   payment_intent: "pi_3LcZSNBrL0aPwZOe3Ouj1QdD",
//   payment_method: "pm_1LcZSsBrL0aPwZOeTv3ocoBM",
//   payment_method_details: {
//     card: {
//       brand: "visa",
//       checks: {
//         address_line1_check: null,
//         address_postal_code_check: "pass",
//         cvc_check: "pass",
//       },
//       country: "US",
//       exp_month: 5,
//       exp_year: 2026,
//       fingerprint: "2w4qkHx29L3jJUHJ",
//       funding: "credit",
//       installments: null,
//       last4: "4242",
//       mandate: null,
//       network: "visa",
//       three_d_secure: null,
//       wallet: null,
//     },
//     type: "card",
//   },
//   receipt_email: null,
//   receipt_number: null,
//   receipt_url:
//     "https://pay.stripe.com/receipts/payment/CAcaFwoVYWNjdF8xTFlEVHBCckwwYVB3Wk9lKLG2uZgGMgalS-6iVPA6LBZr6ReCi3b3RoZLxb-Fbt9N9VHTYFe8dokRH9SItjtYy8rOkyqEQgtNREqf",
//   refunded: false,
//   refunds: {
//     object: "list",
//     data: [],
//     has_more: false,
//     total_count: 0,
//     url: "/v1/charges/ch_3LcZSNBrL0aPwZOe3UYcGqfD/refunds",
//   },
//   review: null,
//   shipping: null,
//   source: null,
//   source_transfer: null,
//   statement_descriptor: null,
//   statement_descriptor_suffix: null,
//   status: "succeeded",
//   transfer_data: null,
//   transfer_group: "c73b5a58-f280-4e40-ad51-913b5bc7b79d",
// };
